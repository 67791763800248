import { InjectionToken } from '@angular/core';
import { TOpen } from '@prlw/libs/event-source/event-source.service';
import { Observable } from 'rxjs';

export const SSE_GATEWAY = new InjectionToken<SseGateway>('sse-gateway');

export interface IChannelUpdateTariff {
  dataType: 'IChannelUpdateTariff';
  userId: string;
  instrumentCode: string;
  price: number;
  destinationStationId: number;
  deliveryTime: number;
}

export interface IChannelUpdateTraderTariff {
  dataType: 'IChannelUpdateTraderTariff';
  destinationStationId: number;
}

export interface IChannelUpdateQuote {
  dataType: 'IChannelUpdateQuote';
  instrumentCode: string;
  timestamp: string;
  type: 'bid' | 'ask';
  orderbook: [number, number][];
}

export interface IChannelUpdateDeal {
  dataType: 'IChannelUpdateDeal';
  dealId: string;
  instrumentCode: string;
  timestamp: string;
  price: number;
  amount: number;
}

export interface SseGateway {
  unicastOpened$: Observable<TOpen>;
  messageIChannelUpdateTraderTariff$: Observable<IChannelUpdateTraderTariff>;
  unicastMessage$: Observable<IChannelUpdateTariff[]>;
  multicastMessage$: Observable<
    (IChannelUpdateTraderTariff | IChannelUpdateQuote | IChannelUpdateDeal)[]
  >;
}
